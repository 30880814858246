import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyChohMbTmrytO7a4BmItAEz6ZlXlkkDZe4",
    authDomain: "soso-32520.firebaseapp.com",
    databaseURL: "https://soso-32520.firebaseio.com",
    projectId: "soso-32520",
    storageBucket: "soso-32520.appspot.com",
    messagingSenderId: "1013614783237",
    appId: "1:1013614783237:web:bc7241dddb660a9f86256c",
    measurementId: "G-QXD2CY1ZCK"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();