import React, { useState, useEffect } from 'react';
import qs from 'qs';
import clsx from 'clsx';
import { auth } from "./firebase";
import {
  makeStyles, Box, Card, CardActions, CardContent,
  Typography, Button, FormControl, InputLabel,
  Input, InputAdornment, IconButton, FormHelperText,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    minWidth: 340,
    maxWidth: 340,
    minHeight: 250
  },
  margin: {
    margin: theme.spacing(0)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: "100%"
  }
}));

const defaultValues = {
  email: "",
  password: "",
  code: "",
  showPassword: false,
  isValidCode: true,
  isLoading: true,
  isPasswordChaged: false,
};



function App({ location }) {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const classes = useStyles();

  const [values, setValues] = useState(defaultValues);

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const checkPasswordValid = () => {
    if (values.password.length < 6) alert('비밀번호를 6자 이상 입력하세요.');
    else {
      auth.confirmPasswordReset(values.code, values.password)
        .then(function () {
          setValues({ isPasswordChaged: true })
        })
        .catch(function () {
          setValues({ isValidCode: false })
        })
    }
  };



  useEffect(() => {
    const oobCode = query.oobCode;
    auth.verifyPasswordResetCode(oobCode)
      .then(function (email) {
        setValues(
          {
            ...defaultValues,
            email: email,
            code: oobCode,
            isValidCode: true,
            isLoading: false,
          }
        )
      })
      .catch(function () {
        setValues(
          {
            ...defaultValues,
            code: oobCode,
            isValidCode: false,
            isLoading: false,
          }
        );
      })
  }, [query.oobCode]);

  return (
    !values.isLoading && <Box display='flex' justifyContent='center'>
      <Card className={classes.card}>
        <CardContent>
          <Box m="0.5rem" />
          < Typography variant='h6' align='center' >
            {values.isValidCode ? '비밀번호 재설정' : values.isPasswordChaged ? '비밀번호 변경 완료' : '비밀번호 재설정 다시시도'}
          </Typography >
          <Box m="1rem" />
          < Typography >
            {values.isValidCode ? '대상 : ' + values.email : values.isPasswordChaged ? '이제 새 비밀번호로 로그인 할 수 있습니다.' : '비밀번호 재설정 요청이 만료되었거나 링크가 이미 사용되었습니다.'}
          </Typography >
          <Box m="1rem" />
          {values.isValidCode && !values.isPasswordChaged &&
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password" >새 비밀번호</InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                } />
              <FormHelperText>영문 숫자를 조합하여 6-14자 이내로 작성해주세요.</FormHelperText>
            </FormControl>}
        </CardContent>
        <CardActions style={{ justifyContent: 'center' }}>
          {values.isValidCode && <Button variant='outlined' size="small" onClick={() => checkPasswordValid()}>제출하기</Button>}
        </CardActions>
      </Card>
    </Box>

  );
}

export default App;
